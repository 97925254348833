import React from "react"
import Next from "../svg/next.svg"
import Prev from "../svg/prev.svg"

const Question10 = ({answerSelected, prevStep, nextStep, answers}) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  }

  const Back = (e) => {
    e.preventDefault();
    prevStep();
  }
  return (
    <div className="card">
      <p className="question-number">Pytanie <span className="number">10</span><span className="normal-text">/</span><span className="number">14</span></p>
      <p className="question">Czy w postępowaniu dotyczącym odpowiedzialności Skarbu Państwa za niesłuszne pozbawienie wolności właściwie został określony termin przedawnienia roszczeń (art. 555 k.p.k.)?</p>
      <label className="custom-radio-container"><input type="radio" name="answer_10" value="a" checked={answers.answer_10 === "a" ? true : false} onChange={answerSelected} /><span className="checkmark"></span> a) Tak.</label>
      <label className="custom-radio-container"><input type="radio" name="answer_10" value="b" checked={answers.answer_10 === "b" ? true : false} onChange={answerSelected} /><span className="checkmark"></span> b) Nie, powinien on zostać zrównany ze standardowym okresem przedawnienia roszczeń majątkowych przewidzianym w k.c. (art. 118 k.c.).</label>
      <label className="custom-radio-container"><input type="radio" name="answer_10" value="c" checked={answers.answer_10 === "c" ? true : false} onChange={answerSelected} /><span className="checkmark"></span> c) Nie, powinien on zostać określony w inny sposób niż wskazany w pkt a i b (jaki?).</label>
      <label>
        <textarea
          name="answer_10_c_text"
          onChange={answerSelected}
          value={answers.answer_10_c_text}
        ></textarea>
      </label>
      <div className="buttons-row">
      <div className="button-nav">
          <button type="button" className="btn-prev" onClick={Back}>
            <Prev/>Poprzednie
          </button>
        </div>
        <div className="button-nav">
          <button type="button" className="btn-next" onClick={Continue}>
            Następne<Next/>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Question10
