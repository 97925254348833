import React from "react"
import Next from "../svg/next.svg"
import Prev from "../svg/prev.svg"

const Question11 = ({answerSelected, prevStep, nextStep, answers}) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  }

  const Back = (e) => {
    e.preventDefault();
    prevStep();
  }
  return (
  <div className="card">
    <p className="question-number">Pytanie <span className="number">11</span><span className="normal-text">/</span><span className="number">14</span></p>
    <p className="question">Czy w postępowaniu dotyczącym odpowiedzialności Skarbu Państwa za niewątpliwie niesłuszne zatrzymanie właściwie został określony początek biegu terminu przedawnienia roszczeń (art. 555 k.p.k.)?</p>
    <label className="custom-radio-container"><input type="radio" name="answer_11" value="a" checked={answers.answer_11 === "a" ? true : false} onChange={answerSelected} /><span className="checkmark"></span> a) Tak.</label>
    <label className="custom-radio-container"><input type="radio" name="answer_11" value="b" checked={answers.answer_11 === "b" ? true : false} onChange={answerSelected} /><span className="checkmark"></span> b) Nie, jeżeli po zatrzymaniu doszło do przedstawienia zarzutu, powinien on rozpoczynać bieg od dnia uprawomocnienia się orzeczenia kończącego postępowanie w sprawie.</label>
    <label className="custom-radio-container"><input type="radio" name="answer_11" value="c" checked={answers.answer_11 === "c" ? true : false} onChange={answerSelected} /><span className="checkmark"></span> c) Nie, powinien on rozpoczynać bieg od innego momentu niż wskazany w pkt a i b (jakiego?).</label>
    <label>
      <textarea
        name="answer_11_c_text"
        onChange={answerSelected}
        value={answers.answer_11_c_text}
      ></textarea>
    </label>
    <div className="buttons-row">
    <div className="button-nav">
        <button type="button" className="btn-prev" onClick={Back}>
          <Prev/>Poprzednie
        </button>
      </div>
      <div className="button-nav">
        <button type="button" className="btn-next" onClick={Continue}>
          Następne<Next/>
        </button>
      </div>
    </div>
  </div>
  )
}

export default Question11
