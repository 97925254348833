import React from "react"
import Next from "../svg/next.svg"
import Prev from "../svg/prev.svg"

const Question09 = ({ answerSelected, prevStep, nextStep, answers}) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  }

  const Back = (e) => {
    e.preventDefault();
    prevStep();
  }
  return (
    <div className="card">
      <p className="question-number">Pytanie <span className="number">9</span><span className="normal-text">/</span><span className="number">14</span></p>
      <p className="question">
        W postępowaniu dotyczącym odpowiedzialności Skarbu Państwa za
        niesłuszne pozbawienie wolności (art. 552-559) stroną powinien być:
      </p>
      <label className="custom-radio-container">
        <input type="radio" name="answer_09" value="a" checked={answers.answer_09 === "a" ? true : false} onChange={answerSelected} />
        <span className="checkmark"></span>
        a) Wnioskodawca i prokurator,
      </label>
      <label className="custom-radio-container">
        <input type="radio" name="answer_09" value="b" checked={answers.answer_09 === "b" ? true : false} onChange={answerSelected} />
        <span className="checkmark"></span>
        b) Wnioskodawca, prokurator oraz Skarb Państwa (reprezentowany przez
        przedstawiciela, np. prezesa sądu, Prokuratorię Generalną RP),
      </label>
      <label className="custom-radio-container">
        <input type="radio" name="answer_09" value="c" checked={answers.answer_09 === "c" ? true : false} onChange={answerSelected} />
        <span className="checkmark"></span>
        c) Wnioskodawca i Skarb Państwa (reprezentowany przez przedstawiciela,
        np. prezesa sądu, Prokuratorię Generalną RP),
      </label>
      <label className="custom-radio-container">
        <input type="radio" name="answer_09" value="d" checked={answers.answer_09 === "d" ? true : false} onChange={answerSelected} />
        <span className="checkmark"></span>
        d) Inna grupa podmiotów – proszę o wskazanie, kto powinien być stroną
        takiego postępowania.
      </label>
      <label>
        <textarea
          name="answer_09_d_text"
          onChange={answerSelected}
          value={answers.answer_09_d_text}
        ></textarea>
      </label>
      <div className="buttons-row">
      <div className="button-nav">
          <button type="button" className="btn-prev" onClick={Back}>
            <Prev/>Poprzednie
          </button>
        </div>
        <div className="button-nav">
          <button type="button" className="btn-next" onClick={Continue}>
            Następne<Next/>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Question09
