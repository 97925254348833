import React from "react"
import Next from "../svg/next.svg"

const Question01 = ({answerSelected, answers, nextStep}) => {

  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  }

  return (
    <div className="card">
      <p className="question-number">Pytanie <span className="number">1</span><span className="normal-text">/</span><span className="number">14</span></p>
      <p className="question">
        Jak ogólnie ocenia Pani/Pan regulacje dotyczące <span className="underlined-text">katalogu form
        pozbawienia wolności</span> (kara pozbawienia wolności, izolacyjny środek
        zabezpieczający, tymczasowe aresztowanie, zatrzymanie), których
        niesłuszne zastosowanie umożliwia pociągnięcie do odpowiedzialności
        Skarbu Państwa na podstawie art. 552-559 k.p.k.?
      </p>
      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_01"
          value="a"
          checked={answers.answer_01 === "a" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        a) Pozytywnie, unormowania te nie wymagają zmian.
      </label>

      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_01"
          value="b"
          checked={answers.answer_01 === "b" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        b) Pozytywnie, choć widzę potrzebę rozszerzenia zakresu
        odpowiedzialności Skarbu Państwa na inne niż przewidziane obecnie formy
        pozbawienia wolności w procesie karnym.
      </label>

      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_01"
          value="c"
          checked={answers.answer_01 === "c" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        c) Negatywnie, przewidziany zakres odpowiedzialności jest zbyt szeroki.
      </label>

      {answers.answer_01 === "b" && (
        <div>
          <p className="question">
            <span className="underlined-text">W przypadku wyboru odpowiedzi „b”</span> proszę o zaznaczenie, jakie to
            formy (można zaznaczyć więcej niż jedną odpowiedź):
          </p>

          <label className="custom-radio-container">
            <input
              type="checkbox"
              name="answer_01_after_choosing_b__a"
              value="a"
              onChange={answerSelected}
              checked={answers.answer_01_after_choosing_b__a === "a" ? true : false}

            />
            <span className="checkmark"></span>
            a) obserwacja psychiatryczna,
          </label>

          <label className="custom-radio-container">
            <input
              type="checkbox"
              name="answer_01_after_choosing_b__b"
              value="b"
              onChange={answerSelected}
              checked={answers.answer_01_after_choosing_b__b === "b" ? true : false}
            />
            <span className="checkmark"></span>
            b) aresztowanie jako kara porządkowa przewidziana w k.p.k.,
          </label>

          <label className="custom-radio-container">
            <input
              type="checkbox"
              name="answer_01_after_choosing_b__c"
              value="c"
              onChange={answerSelected}
              checked={answers.answer_01_after_choosing_b__c === "c" ? true : false}
            />
            <span className="checkmark"></span>
            c) kara pozbawienia wolności z art. 49 § 1 u.s.p.,
          </label>

          <label className="custom-radio-container">
            <input
              type="checkbox"
              name="answer_01_after_choosing_b__d"
              value="d"
              onChange={answerSelected}
              checked={answers.answer_01_after_choosing_b__d === "d" ? true : false}
            />
            <span className="checkmark"></span>
            d) inne (jakie?)
          </label>

          <label>
            <textarea
              name="answer_01_after_choosing_b__d_text"
              onChange={answerSelected}
              value={answers.answer_01_after_choosing_b__d_text}
            ></textarea>
          </label>
        </div>
      )}
      <div className="buttons-row buttons-row-right">
          <div className="button-nav">
            <button type="button" className="btn-next" onClick={Continue}>
              Następne<Next/>
            </button>
          </div>
        </div>
    </div>
  )
}

export default Question01
