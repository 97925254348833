import React from "react"
import Next from "../svg/next.svg"
import Prev from "../svg/prev.svg"

const Question02 = ({answerSelected, answers, nextStep, prevStep}) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  }

  const Back = (e) => {
    e.preventDefault();
    prevStep();
  }

  return (
    <div className="card">
      <p className="question-number">Pytanie <span className="number">2</span><span className="normal-text">/</span><span className="number">14</span></p>
      <p className="question">
        Jaki powinien być Pani/Pana zdaniem zakres odpowiedzialności
        Skarbu Państwa za niesłuszne pozbawienie wolności w toku
        postępowania karnego (zatrzymanie, tymczasowe aresztowanie)?
      </p>

      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_02"
          value="a"
          checked={answers.answer_02 === "a" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        a) Skarb Państwa powinien odpowiadać wyłącznie za bezprawne
        pozbawienie wolności.
      </label>

      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_02"
          value="b"
          checked={answers.answer_02 === "b" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        b) Skarb Państwa powinien odpowiadać za bezprawne pozbawienie
        wolności oraz każde pozbawienie wolności, które okazało się
        niesłuszne w świetle ostatecznego rozstrzygnięcia w danej sprawie
        (niezależnie od okoliczności w danej sprawie nie stwierdzono winy
        i nie orzeczono środków reakcji prawnokarnej albo nie zastosowano
        izolacyjnego środka zabezpieczającego).
      </label>

      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_02"
          value="c"
          checked={answers.answer_02 === "c" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        c) Skarb Państwa powinien odpowiadać za bezprawne pozbawienie
        wolności oraz każde pozbawienie wolności, które okazało się
        niesłuszne w świetle ostatecznego rozstrzygnięcia w danej sprawie
        (nie stwierdzono winy i nie orzeczono środków reakcji prawnokarnej
        albo nie zastosowano izolacyjnego środka zabezpieczającego), <span className="underlined-text">z
        wyjątkiem</span> sytuacji, gdy oskarżony swoim zachowaniem spowodował
        pozbawienie wolności (ucieczka, ukrycie się, stwierdzone matactwo,
        itp.).
      </label>

      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_02"
          checked={answers.answer_02 === "d" ? true : false}
          value="d"
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        d) Inna odpowiedź
      </label>  

      <label>
        <textarea
          name="answer_02_d_text"
          onChange={answerSelected}
          value={answers.answer_02_d_text}
        ></textarea>
      </label>

      <div className="buttons-row">
          <div className="button-nav">
              <button type="button" className="btn-prev" onClick={Back}>
                <Prev/>Poprzednie
              </button>
            </div>
            <div className="button-nav">
              <button type="button" className="btn-next" onClick={Continue}>
                Następne<Next/>
              </button>
            </div>
          </div>
    </div>
  )
}

export default Question02
