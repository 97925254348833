import React from "react"
import Next from "../svg/next.svg"
import Prev from "../svg/prev.svg"

const Question13 = ({ answerSelected, prevStep, nextStep, answers }) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  }

  const Back = (e) => {
    e.preventDefault();
    prevStep();
  }
  return (
    <div className="card">
      <p className="question-number">Pytanie <span className="number">13</span><span className="normal-text">/</span><span className="number">14</span></p>
      <p className="question">
        Czy wnioskodawca w postępowaniu w przedmiocie odpowiedzialności
        Skarbu Państwa za niesłuszne pozbawienie wolności powinien mieć dostęp
        do bezpłatnej pomocy prawnej z urzędu?
      </p>
      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_13"
          value="a"
          checked={answers.answer_13 === "a" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        a) Tak, ale wyłącznie, gdy spełnione jest kryterium dochodowe z art. 78
        § 1 k.p.k.
      </label>
      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_13"
          value="b"
          checked={answers.answer_13 === "b" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        b) Tak, każdorazowo, gdy spełnione jest kryterium dochodowe z art. 78 §
        1 k.p.k. oraz w sytuacji, gdy ustawodawca przewiduje obronę
        obligatoryjną oskarżonego.
      </label>
      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_13"
          value="c"
          checked={answers.answer_13 === "c" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        c) Nie. W żadnej sytuacji.
      </label>
      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_13"
          value="d"
          checked={answers.answer_13 === "d" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        d) Żadne z powyższych. Proszę wskazać jak kwestia ta powinna zostać
        uregulowana.
      </label>
      <label>
        <textarea
          name="answer_13_d_text"
          onChange={answerSelected}
          value={answers.answer_13_d_text}
        ></textarea>
      </label>
      <div className="buttons-row">
      <div className="button-nav">
          <button type="button" className="btn-prev" onClick={Back}>
            <Prev/>Poprzednie
          </button>
        </div>
        <div className="button-nav">
          <button type="button" className="btn-next" onClick={Continue}>
            Następne<Next/>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Question13
