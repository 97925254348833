import React from 'react';

const ThankYou = () => {

  return (
    <div className="card">
      <h2>Serdecznie dziękuję za wypełnienie ankiety.</h2>
      <p>
        W razie potrzeby pozostaję do dyspozycji pod adresem mailowym:
        wojciech.jasinski@uwr.edu.pl
      </p>

      <br/>
      <p>
        Łączę wyrazy szacunku,
        <br /><br />
        Dr hab. Wojciech Jasiński
        <br />
        Katedra Postępowania Karnego
        <br />
        Wydział Prawa, Administracji i Ekonomii
        <br />
        Uniwersytet Wrocławski
      </p>
    </div>  
  )
}

export default ThankYou