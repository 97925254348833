import React from "react"
import Next from "../svg/next.svg"
import Prev from "../svg/prev.svg"

const Question05 = ({answerSelected, prevStep, nextStep, answers}) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  }

  const Back = (e) => {
    e.preventDefault();
    prevStep();
  }
  return (
    <div className="card">
      <p className="question-number">Pytanie <span className="number">5</span><span className="normal-text">/</span><span className="number">14</span></p>
      <p className="question">
        Czy przy ustalaniu przez sąd wysokości należnego
        zadośćuczynienia za niesłuszne pozbawienie wolności powinny być
        uwzględniane następujące czynniki:
      </p>

      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_05"
          value="a"
          checked={answers.answer_05 === "a" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        a) Możliwości finansowe Skarbu Państwa.
      </label>

      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_05"
          value="b"
          checked={answers.answer_05 === "b" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        b) Aktualna stopa życiowa społeczeństwa.
      </label>

      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_05"
          value="c"
          checked={answers.answer_05 === "c" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        c) Obie powyższe okoliczności.
      </label>

      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_05"
          value="d"
          checked={answers.answer_05 === "d" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        d) Żadna z powyższych okoliczności. Zadośćuczynienie powinno być
        ustalane z uwzględnieniem wyłącznie okoliczności indywidualnej
        sprawy.
      </label>

      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_05"
          value="e"
          checked={answers.answer_05 === "e" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        e) Żadna z powyższych okoliczności, ale powinny być uwzględniane
        inne okoliczności niedotyczące bezpośrednio indywidualnej sprawy.
        Jakie?
      </label>

      <label>
        <textarea
          name="answer_05_e_text"
          onChange={answerSelected}
          value={answers.answer_05_e_text}
        ></textarea>
      </label>
      <div className="buttons-row">
      <div className="button-nav">
          <button type="button" className="btn-prev" onClick={Back}>
            <Prev/>Poprzednie
          </button>
        </div>
        <div className="button-nav">
          <button type="button" className="btn-next" onClick={Continue}>
            Następne<Next/>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Question05
