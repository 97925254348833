import React from "react"
import Next from "../svg/next.svg"
import Prev from "../svg/prev.svg"

const Question06 = ({ answerSelected, answers, prevStep, nextStep}) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  }

  const Back = (e) => {
    e.preventDefault();
    prevStep();
  }
  return (
    <div className="card">
      <p className="question-number">Pytanie <span className="number">6</span><span className="normal-text">/</span><span className="number">14</span></p>
      <p className="question">
        Które zdanie najtrafniej oddaje Pani/Pana pogląd na problem
        oddzielania krzywdy wynikłej z samego pozbawienia wolności od krzywdy
        stanowiącej następstwo całego (niesłusznego) postępowania karnego przy
        ustalaniu należnego zadośćuczynienia?{" "}
      </p>

      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_06"
          value="a"
          checked={answers.answer_06 === "a" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        a) Rozdzielenie tych krzywd jest zawsze możliwe przy zastosowaniu ściśle
        określonych kryteriów związku przyczynowego,
      </label>

      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_06"
          value="b"
          checked={answers.answer_06 === "b" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        b) Niekiedy nie jest możliwe rozdzielenie tych krzywd i wówczas należy
        przyjąć, że kompensacie podlegają również te, częściowo wynikające z
        całego postępowania karnego,
      </label>

      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_06"
          value="c"
          checked={answers.answer_06 === "c" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        c) Niekiedy nie jest możliwe rozdzielenie tych krzywd i wówczas należy
        przyjąć, że kompensacie podlegają tylko te, które wynikają wyłącznie z
        pozbawienia wolności.
      </label>

      {(answers.answer_06 === "b" || answers.answer_06 === "c") && (
      <div>
        <p className="question">
          <span className="underlined-text">W przypadku udzielenia odpowiedzi „b” lub „c”</span> proszę wskazać, jaki
          powinien być tryb dochodzenia roszczeń z tytułu szkód i krzywd
          wynikających z niesłusznego postępowania karnego:
        </p>
        <label className="custom-radio-container">
          <input 
            type="radio" 
            name="answer_06_after_choosing_bc" 
            value="a" 
            checked={answers.answer_06_after_choosing_bc === "a" ? true : false} 
            onChange={answerSelected} 
          />
          <span className="checkmark"></span>
            a) Wystarczająca jest możliwość dochodzenia tych roszczeń na drodze cywilnej,
        </label>
        <label className="custom-radio-container">
          <input 
            type="radio" 
            name="answer_06_after_choosing_bc" 
            value="b" 
            checked={answers.answer_06_after_choosing_bc === "b" ? true : false} 
            onChange={answerSelected} 
          />
          <span className="checkmark"></span>
            b) Ustawodawca powinien umożliwić dochodzenia tych roszczeń w ramach specjalnej procedury, tak jak w przypadku odszkodowania za niesłuszne pozbawienie wolności,
        </label>
        <label className="custom-radio-container">
          <input 
            type="radio" 
            name="answer_06_after_choosing_bc"
            value="c" 
            checked={answers.answer_06_after_choosing_bc === "c" ? true : false} 
            onChange={answerSelected} 
          /> 
          <span className="checkmark"></span>
          c) Inna odpowiedź. Jaka?
        </label>

        <label>
          <textarea
            name="answer_06_after_choosing_bc__c_text"
            onChange={answerSelected}
            value={answers.answer_06_after_choosing_bc__c_text}
          ></textarea>
        </label>
      </div>
      )}
      <div className="buttons-row">
        <div className="button-nav">
          <button type="button" className="btn-prev" onClick={Back}>
            <Prev/>Poprzednie
          </button>
        </div>
        <div className="button-nav">
          <button type="button" className="btn-next" onClick={Continue}>
            Następne<Next/>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Question06
