import React from "react"
import Next from "../svg/next.svg"

const Intro = ({nextStep}) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  }
  return (
    <div className="card">
      <p className="justified-text">
        <span className="intro-heading">Szanowna Pani Sędzio/Szanowny Panie Sędzio,</span>
        <br />
        <br />
        Zwracam się z uprzejmą prośbą o wzięcie udziału w ankiecie przygotowanej
        na potrzeby projektu naukowego „Odszkodowanie za niesłuszne pozbawienie
        wolności - teoria i&nbsp;praktyka” realizowanego przez Wydział Prawa,
        Administracji i Ekonomii Uniwersytetu Wrocławskiego, pod moim
        kierownictwem.
      </p>
      <p className="justified-text">
        Projekt uzyskał finansowanie Narodowego Centrum Nauki w ramach konkursu
        Sonata Bis 6. Jego celem jest ustalenie optymalnego modelu kompensowania
        szkód wynikłych z&nbsp;niesłusznego pozbawienia wolności w procesie karnym w
        Polsce. W ramach projektu prowadzone są badania dogmatyczne, analiza
        praktyki orzeczniczej sądów w&nbsp;przedmiocie odszkodowań za niesłuszne
        pozbawienie wolności, a także badanie stanowiska sędziów orzekających w
        sprawach o odszkodowanie lub zadośćuczynienie za niesłusznie skazanie,
        niewątpliwie niesłuszne tymczasowe aresztowanie lub zatrzymanie (art.
        552-559 k.p.k.).
      </p>
      <p className="justified-text">
        Dla osiągnięcia miarodajnych wyników projektu, które miałyby szansę
        stanowić podstawę do formułowania postulatów <em>de lege ferenda</em>, bardzo
        istotne jest zebranie jak największej liczby opinii praktyków, którzy
        orzekają w sprawach odszkodowawczych i&nbsp;posiadają doświadczenie niezbędne
        do oceny obowiązujących regulacji. Z tego powodu <span className="bold-text">zwracam się z uprzejmą
        prośbą o poświęcenie <span className="underlined-text">ok. 15 minut</span> na wypełnienie poniższej ankiety.
        Składa się ona z 14 pytań, których celem jest zbadanie opinii sędziów
        dotyczących węzłowych zagadnień unormowanych w art. 552-559 k.p.k. i
        ustalenie, jaki powinien być optymalny model odpowiedzialności Skarbu
        Państwa za niesłuszne pozbawienie wolności i tryb dochodzenia roszczeń z
        tym związanych.</span>
      </p>
      <p>
        Będę bardzo wdzięczny za poświęcony przez Państwa czas i zaangażowanie.
      </p>
      <p className="justified-text">
        W razie potrzeby pozostaję do dyspozycji pod adresem mailowym:
        wojciech.jasinski@uwr.edu.pl
      </p>
      <br />

      <p>
        Łączę wyrazy szacunku,
        <br /><br />
        Dr hab. Wojciech Jasiński
        <br />
        Katedra Postępowania Karnego
        <br />
        Wydział Prawa, Administracji i Ekonomii
        <br />
        Uniwersytet Wrocławski
      </p>

      <div className="buttons-row buttons-row-right">
        <div className="button-nav">
          <button type="button" className="btn-next" onClick={Continue}>
            Rozpocznij<Next/>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Intro
