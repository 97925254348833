import React, { Component } from "react"

import Intro from "./Intro";
import ThankYou from "./ThankYou";
import Question01 from "./Question01";
import Question02 from "./Question02";
import Question03 from "./Question03";
import Question04 from "./Question04";
import Question05 from "./Question05";
import Question06 from "./Question06";
import Question07 from "./Question07";
import Question08 from "./Question08";
import Question09 from "./Question09";
import Question10 from "./Question10";
import Question11 from "./Question11";
import Question12 from "./Question12";
import Question13 from "./Question13";
import Question14 from "./Question14";

class Survey extends Component {
  constructor(props) {
    super(props)

    this.state = {
      step: 1,
      answers: {
        answer_01: "",
        answer_01_after_choosing_b__a: "",
        answer_01_after_choosing_b__b: "",
        answer_01_after_choosing_b__c: "",
        answer_01_after_choosing_b__d: "",
        answer_01_after_choosing_b__d_text: "",
        answer_02: "",
        answer_02_d_text: "",
        answer_03: "",
        answer_03_d_text: "",
        answer_04: "",
        answer_04_c_text: "",
        answer_05: "",
        answer_05_e_text: "",
        answer_06: "",
        answer_06_after_choosing_bc: "",
        answer_06_after_choosing_bc__c_text: "",
        answer_07: "",
        answer_07_after_choosing_b_text: "",
        answer_07_after_choosing_c: "",
        answer_07_after_choosing_c__d_text: "",
        answer_08: '',
        answer_08_e_text: "",
        answer_09: '',
        answer_09_d_text: '',
        answer_10: '',
        answer_10_c_text: '',
        answer_11: '',
        answer_11_c_text: '',
        answer_12: '',
        answer_12_c_text: '',
        answer_13: '',
        answer_13_d_text: '',
        answer_14: '',
        answer_14_d_text: '',
      },
      isSubmitted: false,
    }
  }
  
  nextStep = () => {
    const { step } = this.state;
    this.setState({step: step + 1});
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({step: step - 1});
  };

  answerSelected = e => {
    let answers = this.state.answers

    switch (e.target.name) {
      case "answer_01":
        answers.answer_01 = e.target.value
        break
      case "answer_01_after_choosing_b__a":
        answers.answer_01_after_choosing_b__a = e.target.value
        break
      case "answer_01_after_choosing_b__b":
        answers.answer_01_after_choosing_b__b = e.target.value
        break
      case "answer_01_after_choosing_b__c":
        answers.answer_01_after_choosing_b__c = e.target.value
        break
      case "answer_01_after_choosing_b__d":
        answers.answer_01_after_choosing_b__d = e.target.value
        break
      case "answer_01_after_choosing_b__d_text":
        answers.answer_01_after_choosing_b__d_text = e.target.value
        break
      case "answer_02":
        answers.answer_02 = e.target.value
        break
      case "answer_02_d_text":
        answers.answer_02_d_text = e.target.value
        break
      case "answer_03":
        answers.answer_03 = e.target.value
        break
      case "answer_03_d_text":
        answers.answer_03_d_text = e.target.value
        break
      case "answer_04":
        answers.answer_04 = e.target.value
        break
      case "answer_04_c_text":
        answers.answer_04_c_text = e.target.value
        break
      case "answer_05":
        answers.answer_05 = e.target.value
        break
      case "answer_05_e_text":
        answers.answer_05_e_text = e.target.value
        break
      case "answer_06":
        answers.answer_06 = e.target.value
        break
      case "answer_06_after_choosing_bc":
        answers.answer_06_after_choosing_bc = e.target.value
        break
      case "answer_06_after_choosing_bc__c_text":
        answers.answer_06_after_choosing_bc__c_text = e.target.value
        break
      case "answer_07":
        answers.answer_07 = e.target.value
        break
      case "answer_07_after_choosing_b_text":
        answers.answer_07_after_choosing_b_text = e.target.value
        break
      case "answer_07_after_choosing_c":
        answers.answer_07_after_choosing_c = e.target.value
        break
      case "answer_07_after_choosing_c__d_text":
        answers.answer_07_after_choosing_c__d_text = e.target.value
        break
      case "answer_08":
        answers.answer_08 = e.target.value
        break
      case "answer_08_e_text":
        answers.answer_08_e_text = e.target.value
        break
      case "answer_09":
        answers.answer_09 = e.target.value
        break
      case "answer_09_d_text":
        answers.answer_09_d_text = e.target.value
        break
      case "answer_10":
        answers.answer_10 = e.target.value
        break
      case "answer_10_c_text":
        answers.answer_10_c_text = e.target.value
        break
      case "answer_11":
        answers.answer_11 = e.target.value
        break
      case "answer_11_c_text":
        answers.answer_11_c_text = e.target.value
        break
      case "answer_12":
        answers.answer_12 = e.target.value
        break
      case "answer_12_c_text":
        answers.answer_12_c_text = e.target.value
        break
      case "answer_13":
        answers.answer_13 = e.target.value
        break
      case "answer_13_d_text":
        answers.answer_13_d_text = e.target.value
        break
      case "answer_14":
        answers.answer_14 = e.target.value
        break
      case "answer_14_d_text":
        answers.answer_14_d_text = e.target.value
        break
      default:
        console.log("test")
    }

    if (e.target.name === "answer_01" && e.target.value !== "b") {
      answers.answer_01_after_choosing_b__a = ""
      answers.answer_01_after_choosing_b__b = ""
      answers.answer_01_after_choosing_b__c = ""
      answers.answer_01_after_choosing_b__d = ""
      answers.answer_01_after_choosing_b__d_text = ""
    }
    if (e.target.name === "answer_01_after_choosing_b__a" && !e.target.checked) {
      answers.answer_01_after_choosing_b__a = ""
    }
    if (e.target.name === "answer_01_after_choosing_b__b" && !e.target.checked) {
      answers.answer_01_after_choosing_b__b = ""
    }
    if (e.target.name === "answer_01_after_choosing_b__c" && !e.target.checked) {
      answers.answer_01_after_choosing_b__c = ""
    }
    if (e.target.name === "answer_01_after_choosing_b__d" && !e.target.checked) {
      answers.answer_01_after_choosing_b__d = ""
    }

    if (
      e.target.name === "answer_06" &&
      (e.target.value !== "b" && e.target.value !== "c")
    ) {
      answers.answer_06_after_choosing_bc = ""
      answers.answer_06_after_choosing_bc__c_text = ""
    }

    if (e.target.name === "answer_07" && e.target.value !== "b") {
      answers.answer_07_after_choosing_b_text = ""
    }

    if (e.target.name === "answer_07" && e.target.value !== "c") {
      answers.answer_07_after_choosing_c = ""
      answers.answer_07_after_choosing_c__d_text = ""
    }

    if (e.target.name === "answer_12" && e.target.value !== "c") {
      answers.answer_12_c_text = ""
    }

    this.setState({ answers: answers });
  }

  render() {
    const { step } = this.state;

    switch(step) {
    case 1:
      return (
        <Intro
          nextStep={this.nextStep} 
        />
      )
    case 2:
      return (
        <Question01
                answerSelected={this.answerSelected}
                answers={this.state.answers}
                nextStep={this.nextStep} 
              />
      )
    case 3:
      return (
        <Question02
          answerSelected={this.answerSelected}
          answers={this.state.answers}
          nextStep={this.nextStep} 
          prevStep={this.prevStep}
        />
      )
    case 4:
      return (
        <Question03
                answerSelected={this.answerSelected}
                answers={this.state.answers}
                nextStep={this.nextStep} 
                prevStep={this.prevStep}
              />
      )
    case 5:
      return (
        <Question04
                answerSelected={this.answerSelected}
                answers={this.state.answers}
                nextStep={this.nextStep} 
                prevStep={this.prevStep}
              />
      )
    case 6:
      return (
        <Question05
                answerSelected={this.answerSelected}
                answers={this.state.answers}
                nextStep={this.nextStep} 
                prevStep={this.prevStep}
              />
      )
    case 7:
      return (
        <Question06
                answerSelected={this.answerSelected}
                answers={this.state.answers}
                nextStep={this.nextStep} 
                prevStep={this.prevStep}
              />
      )
    case 8:
      return (
        <Question07
                answerSelected={this.answerSelected}
                answers={this.state.answers}
                nextStep={this.nextStep} 
                prevStep={this.prevStep}
              />
      )
    case 9:
      return (
        <Question08
                answerSelected={this.answerSelected}
                answers={this.state.answers}
                nextStep={this.nextStep} 
                prevStep={this.prevStep}
              />
      )
    case 10:
      return (
        <Question09
                answerSelected={this.answerSelected}
                answers={this.state.answers}
                nextStep={this.nextStep} 
                prevStep={this.prevStep}
              />
      )
    case 11:
      return (
        <Question10
                answerSelected={this.answerSelected}
                answers={this.state.answers}
                nextStep={this.nextStep} 
                prevStep={this.prevStep}
              />
      )
    case 12:
      return (
        <Question11
                answerSelected={this.answerSelected}
                answers={this.state.answers}
                nextStep={this.nextStep} 
                prevStep={this.prevStep}
              />
      )
    case 13:
      return (
        <Question12
                answerSelected={this.answerSelected}
                answers={this.state.answers}
                nextStep={this.nextStep} 
                prevStep={this.prevStep}
              />
      )
    case 14:
      return (
        <Question13
                answerSelected={this.answerSelected}
                answers={this.state.answers}
                nextStep={this.nextStep} 
                prevStep={this.prevStep}
              />
      )
    case 15:
      return (
        <Question14
                answerSelected={this.answerSelected}
                answers={this.state.answers}
                nextStep={this.nextStep} 
                prevStep={this.prevStep}
              />
      )
    case 16:
      return (
        <ThankYou />
      )
    default:
      return null
      }
  }
}

export default Survey
