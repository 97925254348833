import React, {Component} from "react"
import {getFirebase} from "../firebase"
import Send from "../svg/send.svg"
import Prev from "../svg/prev.svg"

class Question14 extends Component {

  componentDidMount() {
    const lazyApp = import('firebase/app')
    const lazyDatabase = import('firebase/database')
    
    Promise.all([lazyApp, lazyDatabase]).then(([firebase]) => {
      this.database = getFirebase(firebase).database() 
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const date = this.getCurrentDate();
    const submittedAnswers = [date, this.props.answers];
    this.database.ref("usersAnswers").push(submittedAnswers);
    this.props.nextStep();
  }
  
  Back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  }

  getCurrentDate = () => {
    const today = new Date();
    const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date+' '+time;
    return dateTime;
  }
  
  render() {
    return (
      <div className="card">
        <p className="question-number">Pytanie <span className="number">14</span><span className="normal-text">/</span><span className="number">14</span></p>
        <p className="question">
          Jak ocenia Pani/Pan stawkę minimalną opłaty za zastępstwo przez
          adwokata lub radcę prawnego w postępowaniu w przedmiocie odszkodowania
          lub zadośćuczynienia za niesłuszne pozbawienie wolności, wynoszącą
          obecnie 240 zł?
        </p>
        <label className="custom-radio-container">
          <input
            type="radio"
            name="answer_14"
            value="a"
            checked={this.props.answers.answer_14 === "a" ? true : false}
            onChange={this.props.answerSelected}
          />
          <span className="checkmark"></span>
          a) Stawka ta jest odpowiednia.
        </label>
        <label className="custom-radio-container">
          <input
            type="radio"
            name="answer_14"
            value="b"
            checked={this.props.answers.answer_14 === "b" ? true : false}
            onChange={this.props.answerSelected}
          />
          <span className="checkmark"></span>
          b) Stawka ta jest zawyżona, gdyż stopień skomplikowania tych spraw i ich
          specyfika nie wymaga znaczącego nakładu pracy pełnomocnika.
        </label>
        <label className="custom-radio-container">
          <input
            type="radio"
            name="answer_14"
            value="c"
            checked={this.props.answers.answer_14 === "c" ? true : false}
            onChange={this.props.answerSelected}
          />
          <span className="checkmark"></span>
          c) Stawka ta jest zaniżona, gdyż nie zapewnia wnioskodawcy zwrotu
          realnie poniesionych kosztów ustanowienia pełnomocnika.
        </label>
        <label className="custom-radio-container">
          <input
            type="radio"
            name="answer_14"
            value="d"
            checked={this.props.answers.answer_14 === "d" ? true : false}
            onChange={this.props.answerSelected}
          />
          <span className="checkmark"></span>
          d) Żadne z powyższych. Proszę wskazać swoje stanowisko.
        </label>
        <label>
          <textarea
            name="answer_14_d_text"
            onChange={this.props.answerSelected}
            value={this.props.answers.answer_14_d_text}
          ></textarea>
        </label>
        <div className="buttons-row">
        <div className="button-nav">
            <button type="button" className="btn-prev" onClick={this.Back}>
              <Prev/>Poprzednie
            </button>
          </div>

          <form onSubmit={this.handleSubmit}>
            <div className="button-nav">
              <button type="submit" className="btn-next">
                Prześlij<Send/>
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default Question14
