import React from "react"
import Next from "../svg/next.svg"
import Prev from "../svg/prev.svg"

const Question12 = ({answerSelected, answers, prevStep, nextStep}) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  }

  const Back = (e) => {
    e.preventDefault();
    prevStep();
  }
  return (
    <div className="card">
      <p className="question-number">Pytanie <span className="number">12</span><span className="normal-text">/</span><span className="number">14</span></p>
      <p className="question">Czy postępowanie dotyczące odpowiedzialności Skarbu Państwa za niesłuszne pozbawienie wolności powinno być wolne od kosztów sądowych?</p>
      <label className="custom-radio-container"><input type="radio" name="answer_12" value="a" checked={answers.answer_12 === "a" ? true : false} onChange={answerSelected} /><span className="checkmark"></span> a) Tak.</label>
      <label className="custom-radio-container"><input type="radio" name="answer_12" value="b" checked={answers.answer_12 === "b" ? true : false} onChange={answerSelected} /><span className="checkmark"></span> b) Nie. Powinny mieć zastosowanie ogólne przepisy o kosztach sądowych.</label>
      <label className="custom-radio-container"><input type="radio" name="answer_12" value="c" checked={answers.answer_12 === "c" ? true : false} onChange={answerSelected} /><span className="checkmark"></span> c) Nie. Powinna zostać przyjęta szczególna regulacja dotycząca kosztów.</label>
      
      {answers.answer_12 === "c" && (
      <div>
        <p className="question"><span className="underlined-text">W przypadku wyboru odpowiedzi „c”</span> proszę o wskazanie, jak taka regulacja powinna wyglądać.</p>
        <label>
          <textarea
            name="answer_12_c_text"
            onChange={answerSelected}
            value={answers.answer_12_c_text}
            ></textarea>
        </label>
      </div>
      )}
      <div className="buttons-row">
      <div className="button-nav">
          <button type="button" className="btn-prev" onClick={Back}>
            <Prev/>Poprzednie
          </button>
        </div>
        <div className="button-nav">
          <button type="button" className="btn-next" onClick={Continue}>
            Następne<Next/>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Question12
