import React from "react"
import Next from "../svg/next.svg"
import Prev from "../svg/prev.svg"

const Question04 = ({answerSelected, prevStep, nextStep, answers}) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  }

  const Back = (e) => {
    e.preventDefault();
    prevStep();
  }
  return (
    <div className="card">
      <p className="question-number">Pytanie <span className="number">4</span><span className="normal-text">/</span><span className="number">14</span></p>
      <p className="question">
        Czy aktualnie obowiązujące reguły zasądzania przez sąd
        <span className="underlined-text"> zadośćuczynienia</span> za niesłuszne pozbawienie wolności wymagają
        zmian?
      </p>

      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_04"
          value="a"
          checked={answers.answer_04 === "a" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        a) Nie. Rozwiązania te są optymalne.
      </label>

      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_04"
          value="b"
          checked={answers.answer_04 === "b" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        b) Tak. Zasadne byłoby wprowadzenie ujednoliconej metody
        wyliczania zadośćuczynienia za okres niesłusznego pozbawienia
        wolności (np. zryczałtowanych kwot za jeden dzień, określenie
        szczegółowych reguł dotyczących sposobu obliczania kwot
        zadośćuczynienia przez sąd, itp.).
      </label>

      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_04"
          value="c"
          checked={answers.answer_04 === "c" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        c) Tak. Zasadne było wprowadzenie innych korekt. Jakich?
      </label>

      <label>
        <textarea
          name="answer_04_c_text"
          onChange={answerSelected}
          value={answers.answer_04_c_text}
        ></textarea>
      </label>

      <div className="buttons-row">
      <div className="button-nav">
          <button type="button" className="btn-prev" onClick={Back}>
            <Prev/>Poprzednie
          </button>
        </div>
        <div className="button-nav">
          <button type="button" className="btn-next" onClick={Continue}>
            Następne<Next/>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Question04
