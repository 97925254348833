import React from "react";
import "../index.css";
import Survey from "../components/Survey";
// import Intro from "../components/Intro";
// import ThankYou from "../components/ThankYou";

export default function Home() {
  return (
    <div className="App">
      {/* <Intro/> */}
      <Survey/>
      {/* <ThankYou/> */}
    </div>
  )
}
