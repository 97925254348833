
const config = {
  apiKey: "AIzaSyBPqou6Ceim53YwEH4QNIJ_V6oCvY9YxJw",
  authDomain: "ankieta-wojciech-jasinski.firebaseapp.com",
  databaseURL: "https://ankieta-wojciech-jasinski.firebaseio.com",
  projectId: "ankieta-wojciech-jasinski",
  storageBucket: "ankieta-wojciech-jasinski.appspot.com",
  messagingSenderId: "431261137471",
  appId: "1:431261137471:web:0e408aee53881e8b48168a",
  measurementId: "G-SYB91KFM7B"
}

let firebaseInstance
export const getFirebase = firebase => {
  if (firebaseInstance) {
    return firebaseInstance
  }

  firebase.initializeApp(config)
  firebaseInstance = firebase

  return firebase
}