import React from "react"
import Next from "../svg/next.svg"
import Prev from "../svg/prev.svg"

const Question07 = ({answerSelected, answers, prevStep, nextStep}) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  }

  const Back = (e) => {
    e.preventDefault();
    prevStep();
  }
  return (
    <div className="card">
      <p className="question-number">Pytanie <span className="number">7</span><span className="normal-text">/</span><span className="number">14</span></p>
      <p className="question">Jak ogólnie ocenia Pani/Pan unormowania dotyczące trybu dochodzenia roszczeń z tytułu odpowiedzialności Skarbu Państwa za niesłuszne pozbawienie wolności (art. 552-559 k.p.k.)? </p>
      <label className="custom-radio-container">
        <input 
          type="radio" 
          name="answer_07" 
          value="a"
          checked={answers.answer_07 === "a" ? true : false} 
          onChange={answerSelected} 
        />
        <span className="checkmark"></span>
          a) Pozytywnie, nie wymagają one zmian.
      </label>
      <label className="custom-radio-container">
        <input 
          type="radio" 
          name="answer_07" 
          value="b" 
          checked={answers.answer_07 === "b" ? true : false} 
          onChange={answerSelected} 
        />
        <span className="checkmark"></span>
          b) Pozytywnie, choć widzę potrzebę wprowadzenia korekt.
      </label>
      <label className="custom-radio-container">
        <input 
          type="radio" 
          name="answer_07" 
          value="c" 
          checked={answers.answer_07 === "c" ? true : false} 
          onChange={answerSelected} 
        />
        <span className="checkmark"></span>
          c) Negatywnie, przewidziany tryb dochodzenia roszczeń jest niewłaściwy.
      </label>

      {answers.answer_07 === "b" && (
        <div>
          <p className="question"><span className="underlined-text">W przypadku wyboru odpowiedzi „b”</span> proszę o wskazanie, jakie to korekty:</p>
          <label>
            <textarea
              name="answer_07_after_choosing_b_text"
              onChange={answerSelected}
              value={answers.answer_07_after_choosing_b_text}
            ></textarea>
          </label>
        </div>
      )}

      {answers.answer_07 === "c" && (
        <div>
          <p className="question"><span className="underlined-text">W przypadku wyboru odpowiedzi „c”</span> proszę o wskazanie jaki tryb dochodzenia roszczeń z tytułu niesłusznego pozbawienia wolności uznaje Pani/Pan za optymalny?</p>
          <label className="custom-radio-container">
            <input 
              type="radio" 
              name="answer_07_after_choosing_c" 
              value="a"
              checked={answers.answer_07_after_choosing_c === "a" ? true : false}  
              onChange={answerSelected}
            />
            <span className="checkmark"></span>
              a) Postępowanie cywilne toczące się na zasadach ogólnych przewidzianych w k.p.c.
          </label>
          <label className="custom-radio-container">
            <input 
              type="radio" 
              name="answer_07_after_choosing_c" 
              value="b"
              checked={answers.answer_07_after_choosing_c === "b" ? true : false} 
              onChange={answerSelected} 
            />
            <span className="checkmark"></span>
              b) Postępowanie cywilne o szczególnym charakterze uregulowane w k.p.c.
          </label>
          <label className="custom-radio-container">
            <input 
              type="radio" 
              name="answer_07_after_choosing_c" 
              value="c" 
              checked={answers.answer_07_after_choosing_c === "c" ? true : false} 
              onChange={answerSelected} 
            /> 
            <span className="checkmark"></span>
              c) Postępowanie administracyjne toczące się przed organem państwa (np. Minister Sprawiedliwości, prokurator kierujący powszechną jednostką prokuratury, prezes sądu powszechnego, itp.), w ramach którego wydawana jest decyzja, którą można zaskarżyć do sądu cywilnego.
          </label>
          <label className="custom-radio-container">
            <input 
            type="radio" 
            name="answer_07_after_choosing_c" 
            value="d" 
            checked={answers.answer_07_after_choosing_c === "d" ? true : false} 
            onChange={answerSelected} 
          />
          <span className="checkmark"></span>
            d) Inny (jaki?)
          </label>
          <label>
            <textarea
              name="answer_07_after_choosing_c__d_text"
              onChange={answerSelected}
              value={answers.answer_07_after_choosing_c__d_text}
            ></textarea>
          </label>
        </div>
      )}
      <div className="buttons-row">
      <div className="button-nav">
          <button type="button" className="btn-prev" onClick={Back}>
            <Prev/>Poprzednie
          </button>
        </div>
        <div className="button-nav">
          <button type="button" className="btn-next" onClick={Continue}>
            Następne<Next/>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Question07
