import React from "react"
import Next from "../svg/next.svg"
import Prev from "../svg/prev.svg"

const Question08 = ({answerSelected, prevStep, nextStep, answers}) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  }

  const Back = (e) => {
    e.preventDefault();
    prevStep();
  }
  return (
    <div className="card">
      <p className="question-number">Pytanie <span className="number">8</span><span className="normal-text">/</span><span className="number">14</span></p>
      <p className="question">Jaki skład sądu uznaje Pani/Pan za optymalny w ramach obecnie obowiązującego modelu postępowania w przedmiocie odpowiedzialności Skarbu Państwa z tytułu niesłusznego pozbawienia wolności (art. 552-559 k.p.k.)?</p>
      <label className="custom-radio-container"><input type="radio" name="answer_08" value="a" checked={answers.answer_08 === "a" ? true : false} onChange={answerSelected} /><span className="checkmark"></span> a) Jednego sędziego.</label>
      <label className="custom-radio-container"><input type="radio" name="answer_08" value="b" checked={answers.answer_08 === "b" ? true : false} onChange={answerSelected} /><span className="checkmark"></span> b) Trzech sędziów orzekających w wydziale karnym.</label>
      <label className="custom-radio-container"><input type="radio" name="answer_08" value="c" checked={answers.answer_08 === "c" ? true : false} onChange={answerSelected} /><span className="checkmark"></span> c) Trzech sędziów (przynajmniej po jednym orzekającym w wydziale karnym i cywilnym).</label>
      <label className="custom-radio-container"><input type="radio" name="answer_08" value="d" checked={answers.answer_08 === "d" ? true : false} onChange={answerSelected} /><span className="checkmark"></span> d) Jeden sędzia i dwóch ławników.</label>
      <label className="custom-radio-container"><input type="radio" name="answer_08" value="e" checked={answers.answer_08 === "e" ? true : false} onChange={answerSelected} /><span className="checkmark"></span> e) Inny (jaki?)</label>
      <label>
        <textarea
          name="answer_08_e_text"
          onChange={answerSelected}
          value={answers.answer_08_e_text}
        ></textarea>
      </label>
      <div className="buttons-row">
      <div className="button-nav">
          <button type="button" className="btn-prev" onClick={Back}>
            <Prev/>Poprzednie
          </button>
        </div>
        <div className="button-nav">
          <button type="button" className="btn-next" onClick={Continue}>
            Następne<Next/>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Question08
