import React from "react"
import Next from "../svg/next.svg"
import Prev from "../svg/prev.svg"

const Question03 = ({answerSelected, prevStep, nextStep, answers}) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  }

  const Back = (e) => {
    e.preventDefault();
    prevStep();
  }
  return (
    <div className="card">
      <p className="question-number">Pytanie <span className="number">3</span><span className="normal-text">/</span><span className="number">14</span></p>
      <p className="question">
        Czy uważa Pani/Pan, że zaliczenie okresu
        zatrzymania/tymczasowego aresztowania na poczet orzekanych środków
        reakcji prawnokarnej w tym samym albo innym postępowaniu stanowi
        optymalny sposób kompensowania niewątpliwie niesłusznego
        pozbawienia wolności?
      </p>

      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_03"
          value="a"
          checked={answers.answer_03 === "a" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        a) Tak. Zaliczenie w każdej sytuacji w całości kompensuje szkody i
        krzywdy poniesione w wyniku niewątpliwie niesłusznego pozbawienia
        wolności.
      </label>

      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_03"
          value="b"
          checked={answers.answer_03 === "b" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        b) Co do zasady tak, choć zaliczenie okresu niewątpliwie
        niesłusznego zatrzymania/tymczasowego aresztowania kompensuje w
        pełni poniesione szkody i krzywdy tylko wtedy, gdy chodzi o
        zaliczenie na poczet kary pozbawienia wolności. W innych
        sytuacjach nie dochodzi do pełnej kompensacji, co sprawia, że
        możliwe jest zasądzenie odszkodowania lub zadośćuczynienia.
      </label>

      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_03"
          value="c"
          checked={answers.answer_03 === "c" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        c) Nie, sąd powinien tę kwestię w przypadku każdego rodzaju
        zaliczenia badać i ustalać, w jakim stopniu doszło do kompensacji
        szkód i krzywd, a w jakim zasadne jest zasądzenie odszkodowania
        albo zadośćuczynienia.
      </label>

      <label className="custom-radio-container">
        <input
          type="radio"
          name="answer_03"
          value="d"
          checked={answers.answer_03 === "d" ? true : false}
          onChange={answerSelected}
        />
        <span className="checkmark"></span>
        d) Inna odpowiedź
      </label>

      <label>
        <textarea
          name="answer_03_d_text"
          onChange={answerSelected}
          value={answers.answer_03_d_text}
        ></textarea>
      </label>

      <div className="buttons-row">
        <div className="button-nav">
            <button type="button" className="btn-prev" onClick={Back}>
              <Prev/>Poprzednie
            </button>
          </div>
          <div className="button-nav">
            <button type="button" className="btn-next" onClick={Continue}>
              Następne<Next/>
            </button>
          </div>
        </div>
    </div>
  )
}

export default Question03
